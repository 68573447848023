module.exports = {
  'title': 'Indie Games Mexico',
  'blog': 'Blog',
  'menu.ourstory': 'Historia',
  'menu.ourstory.link': '/es/historia',
  'menu.games': 'Juegos',
  'menu.games.link': '/es/juegos',
  'menu.studios': 'Estudios',
  'menu.studios.link': '/es/estudios',
  'menu.careers': 'Carreras',
  'menu.internship': 'Estadias',
  'menu.intership.link': '/es/estadias',
  'menu.community': 'Comunidad',
  'menu.community.link': '/es/comunidad',
  'menu.news': 'Noticias',
  'menu.news.link': '/es/noticias',
  'language': 'Seleccione Lenguage',
  'localeName': 'Español MX',
  'locale': 'es',
  'site.search': 'Buscar',
  'seo.description': 'Empresa Mexicana de Videos Juegos',
  'seo.keywords': 'Video juegos',
  'join.us': 'Unete a',
  'follow.us': 'Siguenos en',
  'subscribe.on': 'Subscribete en ',
  'find.us': 'Encuentranos en',
  'footer.trademark': 'Indie Games México. Marcas registradas pertenecen a Game Dev MX S de R.L. de C.V. Todos los derechos reservados.',
  'by': 'Por',
  'published': 'Publicado en',
  'readmore': 'Leer mas',
  'next': 'Siguiente',
  'prev': 'Previo'
};
